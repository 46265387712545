import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const jointNames = (bodyPart) => {
  if (bodyPart) {
    const formattedName = bodyPart
      .split('_')
      .filter((word) => word !== 'angle')
      .map((word, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <React.Fragment key={`${word}-${index}`}>
          {index > 0 && ' '}
          {word.charAt(0).toUpperCase() + word.slice(1)}
        </React.Fragment>
      ));
    return formattedName;
  }
  return '';
};
const formatExerciseName = (name) => {
  if (!name) return '';

  // Split the name by underscores
  const words = name.split('_');

  // Remove the last word (e.g., 'angle') if words array is not empty
  if (words.length > 0) {
    words.pop();
  }

  // Capitalize the remaining words and join them with spaces
  return words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

function Charty({
  jointAngleData1,
  jointAngleData2,
  thresholdUpper,
  thresholdLower,
  exerciseName,
  targetExercise,
}) {
  const { repCount } = targetExercise;
  // const numReps = 15;

  // const padArray = (array) => {
  //   return array.length < numReps
  //     ? [...array, ...Array(numReps - array.length).fill(null)]
  //     : array.slice(0, numReps);
  // };

  const thresholdUpperArray = Array(jointAngleData2.length).fill(thresholdUpper);
  const thresholdLowerArray = Array(jointAngleData2.length).fill(thresholdLower);
  // const thresholdUpperArray = Array(numReps).fill(thresholdUpper);
  // const thresholdLowerArray = Array(numReps).fill(thresholdLower);
  const data = {
    labels: jointAngleData2.map((_, index) => (index + 1).toString().padStart(2, '0')),
    // labels: Array.from({ length: numReps }, (_, index) => (index + 1).toString().padStart(2, '0')),
    datasets: [
      {
        label: `${formatExerciseName(exerciseName)} `,
        data: jointAngleData1, // Data for the first joint angle
        // data: padArray(jointAngleData1), // Ensure data length is 12

        borderColor: '#34B97D', // Color for the first line (e.g., green)
        backgroundColor: '#34B97D',
        borderWidth: 4,
        pointRadius: jointAngleData1.map((_, index) => (index + 1 === repCount ? 10 : 4)), // Highlight repCount
        // pointRadius: padArray(jointAngleData1).map((_, index) => (index + 1 === repCount ? 8 : 4)), // Highlight repCount
        pointBackgroundColor: jointAngleData1.map((_, index) =>
          index + 1 === repCount ? 'transparent' : '#1EB34A',
        ),
        pointBorderColor: '#1EB34A',
        clip: false, // Disable clipping of points outside the chart area
      },
      {
        label: `${formatExerciseName(exerciseName)} `,
        data: jointAngleData2, // Data for the second joint angle
        // data: padArray(jointAngleData2), // Ensure data length is 12
        borderColor: '#21706F', // Color for the second line (e.g., blue)
        backgroundColor: '#21706F',
        borderWidth: 4,
        pointRadius: jointAngleData2.map((_, index) => (index + 1 === repCount ? 10 : 4)), // Highlight repCount
        // pointRadius: padArray(jointAngleData2).map((_, index) => (index + 1 === repCount ? 8 : 4)), // Highlight repCount
        pointBackgroundColor: jointAngleData2.map((_, index) =>
          index + 1 === repCount ? 'transparent' : '#3D77A6',
        ),
        pointBorderColor: '#3D77A6',
        clip: false, // Disable clipping of points outside the chart area
      },
      {
        label: 'Threshold Upper',
        data: thresholdUpperArray, // Upper threshold line
        borderColor: '#000000',
        borderWidth: 1,
        borderDash: [5, 5], // Dashed line
        fill: false,
        pointRadius: 0, // No points on the threshold line
      },
      {
        label: 'Threshold Lower',
        data: thresholdLowerArray, // Lower threshold line
        borderColor: '#000000',
        borderWidth: 1,
        borderDash: [5, 5], // Dashed line
        fill: false,
        pointRadius: 0, // No points on the threshold line
      },
    ],
  };
  const options = {
    maintainAspectRatio: false, // This allows manual control of the canvas size
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
        display: false,
      },
      title: {
        display: false,
      },
    },
    layout: {
      padding: {
        top: 20,
        bottom: 20,
        left: 10,
        right: 10,
      },
    },
    scales: {
      y: {
        // beginAtZero: true,
        // min: yAxisMin,
        title: {
          display: true,
          text: 'Angle (In Degrees)',
          color: '#000000',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
        grid: {
          drawBorder: true, // Draw border on the Y-axis
          color(context) {
            if (context.tick.value === context.chart.scales.y.min) {
              return '#000000'; // Draw line only at the 0 index
            }
            return 'transparent'; // Make other grid lines invisible
          },
          lineWidth: 1,
        },
        ticks: {
          stepSize: 10, // Set the step size to 10 for the Y-axis
          callback(value) {
            return value % 10 === 0 ? value : ''; // Only display multiples of 10
          },
          padding: 1, // Adjust padding to reduce space between ticks
          autoSkip: false, // Ensure that all labels are shown
          color: '#919b9b', // Change tick label color for y-axis
        },
      },
      x: {
        title: {
          display: true,
          text: 'Repetition Number',
          color: '#000000',
          font: {
            size: 16,
            weight: 'bold',
          },
        },
        grid: {
          drawBorder: true, // Draw border on the X-axis
          color(context) {
            if (context.index === 0) {
              return '#000000'; // Draw line only at the 0 index
            }
            return 'transparent'; // Make other grid lines invisible
          },
          lineWidth: 1,
        },
        ticks: {
          color: '#919b9b', // Change tick label color for x-axis
        },
      },
    },
  };

  return (
    <div style={{ height: '300px', width: '100%' }}>
      <p className="selectedExerciseNameOnChart">{jointNames(exerciseName)}</p>
      <Line options={options} data={data} />
    </div>
  );
}

export default Charty;
